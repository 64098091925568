import React from "react";
import styles from "./Styles/NavBar.module.css";

import Dropdown from 'react-bootstrap/Dropdown';
//  

// Icons
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// Icons

const NavBar = () => {
  return (
    <nav className={styles.Nav_Bar}>
      <div className={styles.Nav_Wrapper}>
        <div className={styles.Right_Side}>
          <img
            src={require("../../../Assets/Icons/Patreon-Logo-Red.png")}
            alt=""
            className={styles.Logo}
          />

          <ul className={styles.Dropdown_Container}>
            <li className={styles.Dropdown_Item}>
              <a href="#" className={styles.Dropdown_Link}>
                Product
              </a>
              <KeyboardArrowDownIcon sx={{ fontSize: 15 }} />
            </li>
            <li className={styles.Dropdown_Item}>
              <a href="#" className={styles.Dropdown_Link}>
                For Creators
              </a>
              <KeyboardArrowDownIcon sx={{ fontSize: 15 }} />
            </li>
            <li className={styles.Dropdown_Item}>
              <a href="#" className={styles.Dropdown_Link}>
                Pricing
              </a>
            </li>
            <li className={styles.Dropdown_Item}>
              <a href="#" className={styles.Dropdown_Link}>
                Resources
              </a>
              <KeyboardArrowDownIcon sx={{ fontSize: 15 }} />
            </li>
            <li className={styles.Dropdown_Item}>
              <a href="#" className={styles.Dropdown_Link}>
                Starter Kits
              </a>
            </li>
          </ul>
        </div>

        <div className={styles.Left_Side}>
          <div className={styles.Input_Wrapper}>
            <SearchIcon />
            <input
              type="text"
              placeholder="Find a creator"
              className={styles.Nav_Input}
            />
          </div>

          <a href="#" className={styles.Login_Link}>Log In</a>

          <div className={styles.Patreon_Button}>
            <p className={styles.Button_Text}>Create On Patreon</p>
          </div>

          <div className={styles.Icon_Wrapper}>
            <MenuIcon />
          </div>
        </div>
      </div>

      <div className={styles.Responsive_Input_Wraper}>
        <SearchIcon />
        <input
          placeholder="Find a creator"
          type="text"
          className={styles.Responsive_Input}
        />
      </div>
    </nav>
  );
};

export default NavBar;
